/* Container for the entire registration form */
.registration_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for each attendee form */
.attendee_form {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
}

/* Two-row layout for form fields */
.form_row {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

/* Input fields */
.form_row input[type="text"],
.form_row input[type="tel"],
.form_row input[type="email"],
.form_row input[type="number"] {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Checkbox alignment */
.form_row label {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Submit button styling */
.submit_button {
    background-color: #9a730d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit_button:hover {
    background-color: #815e0b;
}

/* Confirmation message styling */
.confirmation_message {
    text-align: center;
    padding: 30px;
    background-color: #e6f7e6;
    border: 1px solid #b3e6b3;
    border-radius: 6px;
    color: #2e7d32;
}

/* Validation error message styling */
.validation_error {
    color: #d32f2f;
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: bold;
}

/* CAPTCHA error message styling */
.captcha_error {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
}

.submit_button {
    background-color: #9a730d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

.submit_button:hover {
    background-color: #815e0b;
}



.registration_summary {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.registration_summary h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.registration_summary p {
    margin: 5px 0;
}

.registration_summary ul {
    padding-left: 20px;
}

.registration_summary li {
    margin-bottom: 15px;
    list-style-type: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}


/* Table container styles */
.registration_table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    font-size: 16px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
}

/* Table headers */
.registration_table thead {
    background-color: #9a730d;
    color: #fff;
}

.registration_table th {
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 2px solid #815e0b;
}

/* Table rows */
.registration_table tr {
    border-bottom: 1px solid #ddd;
}

.registration_table tr:nth-child(even) {
    background-color: #f4f4f4;
}

/* Table data cells */
.registration_table td {
    padding: 10px;
    text-align: left;
}

/* Highlight row on hover */
.registration_table tr:hover {
    background-color: #eee;
}

/* Make table responsive */
@media (max-width: 768px) {
    .registration_table {
        font-size: 14px;
    }

    .registration_table th, .registration_table td {
        padding: 8px;
    }
}
