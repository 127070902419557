

.title {
    font-size:36pt;
}
.verse_ref {
    font-size:24pt;
}
.entry_p {
    font-size:24pt;
}

.mpd_verse_large {
    font-size:32pt;
    color: #ffffff;
    text-shadow: 2px 2px 3px black;
    line-height:1.4;
    font-weight:bold;
}
.mpd_verse_ref_caps {
    text-transform: uppercase;
    font-size:24pt;
    font-weight:bold;
    display:flex;
    justify-content: left;
    color: #ff9595;
    text-shadow: 2px 2px 3px #000000;
}
.mpd_verse_holder_div {
    padding:20px;
}
.mpd_title {
    font-weight:bold;
}
.mpd_body {
    padding:20px 50px 0 50px;
    font-size:16pt;
    line-height:1.5;
}
.devotional_audio_div {
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}